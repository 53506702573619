<template>
  <div class="cards-filters-beeline">


    <div class="cards-filters-beeline-side">
      <search-input
        v-if="isFilterVisible('search')"
        style="width: 300px"
        v-model="filters.search"
        @change="filtersChanged"
      >
      </search-input>

      <cards-year-select
        v-if="isFilterVisible('years')"
        style="width:100px"
        v-model="filters.years"
        @change="filtersChanged"
      ></cards-year-select>

<!--      <cards-period-select-->
<!--        v-if="isFilterVisible('periods')"-->
<!--        style="width:100px"-->
<!--        v-model="filters.periods"-->
<!--        @change="filtersChanged"-->
<!--      ></cards-period-select>-->

      <cards-stage-select
        v-if="isFilterVisible('stages')"
        multiple
        v-model="filters.stages"
        @change="filtersChanged"
      ></cards-stage-select>

      <cards-status-select
        v-model="filters.statuses"
        placeholder="Статус"
        style="width: 190px"
        @change="filtersChanged"
      ></cards-status-select>

      <cards-stage-negotiation-select
        v-if="isFilterVisible('negotiations')"
        v-model="filters.negotiations"
        multiple
        @change="filtersChanged"
      ></cards-stage-negotiation-select>

      <el-select
        v-model="availableCards"
        multiple
        placeholder="Доступные карты"
      >
        <el-option label="Мои карты" value="my"></el-option>
        <el-option label="На моем шаге" value="my_turn"></el-option>
        <el-option label="Карты непосредственных подчиненных" value="subordinates"></el-option>
        <el-option label="Карты всех подчиненных" value="allSubordinates"></el-option>
      </el-select>

      <el-select
        v-model="filters.closed_route"
        style="width: 180px"
        placeholder="Закрытый маршрут"
        clearable
        @change="filtersChanged"
      >
        <el-option label="Да" :value="1"></el-option>
        <el-option label="Нет" :value="0"></el-option>
      </el-select>

      <!--      <search-input-->
      <!--        placeholder="Ответственный"-->
      <!--        style="width: 200px"-->
      <!--        v-model="filters.negotiation_user_fio"-->
      <!--        @change="filtersChanged"-->
      <!--      >-->
      <!--      </search-input>-->
    </div>


    <div class="cards-filters-beeline-side">
      <el-button
        v-if="$canAndRulesPass(user, 'setting.back_integration')"
        size="small"
        :loading="loadingIntegration"
        @click="doIntegration"
      >
	      Выгрузить данные в SAP
      </el-button>

      <el-button
        v-if="!filterDeleted && isFilterVisible('export_cards') && $canAndRulesPass(user, 'card.export_list_for_export')"
        style="margin-left:0"
        size="small"
        icon="fas fa-file-excel"
        @click="exportToExcel"
      >
        Экспорт в XLSX
      </el-button>
    </div>



  </div>
</template>

<script>

import SearchInput from "@/components/filters/SearchInput";
import CardsYearSelect from "@/components/filters/cards/CardsYearSelect";
import CardsStageSelect from "@/components/filters/cards/CardsStageSelect";
import CardsStageNegotiationSelect from "@/components/filters/cards/CardsStageNegotiationSelect.vue";
import CardsPeriodSelect from "@/components/filters/cards/CardsPeriodSelect.vue";
import {downloader} from "@/mixins/downloader";
import {mapGetters} from "vuex";
import requestSender from "@/api/base/requestSender";
import CardsStatusSelect from "@/components/filters/cards/CardsStatusSelect.vue";

export default {
  name: "cards-page-filters",
  mixins: [downloader],
  components: {
    CardsStatusSelect,
    CardsPeriodSelect, CardsStageNegotiationSelect, CardsStageSelect, CardsYearSelect, SearchInput},

  props: {
    value: {},
    hidden: {type: Array},
    filterDeleted: {type: Boolean, default: false},
  },

  computed: {
    ...mapGetters(['user'])
  },
  watch: {
    availableCards: function () {
      this.filters.my = this.availableCards.includes('my') ? 1 : null;
      this.filters.my_turn = this.availableCards.includes('my_turn') ? 1 : null;

      if( this.availableCards.includes('allSubordinates') ){
        this.filters.subordinates = this.$constants.user.subordinates.SUBORDINATES_ALL;
      } else if( this.availableCards.includes('subordinates') ){
        this.filters.subordinates = this.$constants.user.subordinates.SUBORDINATES_DIRECT;
      } else {
        this.filters.subordinates = this.$constants.user.subordinates.SUBORDINATES_NONE;
      }



      this.filtersChanged();
    },
  },
  data() {
    return {
      loadingIntegration: false,

      subordinates: false,
      allSubordinates: false,

      availableCards: [],
      filters: {
        search: null,
        years: [],
        periods: [],
        stages: [],
        statuses: [],
        negotiations: [],
        negotiation_user_fio: null,
        my: null,
        my_turn: null,
        subordinates: null,
        closed_route: null,
      },
    }
  },
  beforeMount() {
    if( this.$route.query.my ){
      this.availableCards.push('my');
    }
    if( this.$route.query.my_turn ){
      this.availableCards.push('my_turn');
    }
    if( this.filters.my_turn ){
      this.subordinates = false;
    }
  },
  mounted() {
    this.filters = {...this.filters, ...this.value};
    if( this.filters.my_turn ){
      this.filters.my = null;
      this.filters.allSubordinates = 1;
    }

    this.filtersChanged()
  },
  methods: {
    filtersChanged() {
      this.$emit('input', this.filters);
      this.$emit('change', this.filters);
    },

    preCheckChange(filter, value) {
      if (filter === 'my' && value) {
        this.subordinates = false;
        this.allSubordinates = false;
      }
      if (filter === 'subordinates' && value) {
        this.filters.my = null;
        this.filters.allSubordinates = null;
      }
      if (filter === 'subordinates' && !value) {
        this.filters.allSubordinates = null;
      }
      this.filtersChanged();
    },

    isFilterVisible(filterName){
      if( this.hidden && this.hidden.includes(filterName) ){
        return false;
      }
      return true;
    },

    exportToExcel() {
      this.downloadFile(
        'export.xlsx',
        this.$api.exportImport.export,
        {
          entity: 'cards',
          data: this.filters
        }
      )
    },

    doIntegration(){
      this.loadingIntegration = true;
      requestSender('get', 'cherkizovo-back-integration/upload-cards-data', {
        ...this.filters,
      })
        .then(data => {
          this.$notify.success({
            title: 'Отправлено',
            message: 'Задача на интеграцию успешно добавлена в очередь'
          });
        })
        .finally(()=>{
          this.loadingIntegration = false;
        })
    },
  }
}
</script>


<style lang="scss">

.cards-filters-beeline {
  display: flex;
  justify-content: space-between;

  &-side {
    align-items: flex-start;
    display: flex;
    gap: 1.5rem;
  }
}
</style>